import axios from "axios";

// Check for token in localStorage after reload
const token = sessionStorage.getItem('token'); // Use sessionStorage if required
// If token exists, set it to Axios headers
if (token) {
  axios.defaults.headers.common['Authorization'] = `Token ${token}`;
}

const axiosInstance = axios.create({
  baseURL: window.$http, // Replace with your Django backend URL
  timeout: 360000, // 6 mins timeout
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Token ${token}`,
  },
});

export default axiosInstance;
