<template>
    <div class="container-fluid overflow-auto">
        <div class="card" id="registerCard" ref="registerCard">
        <div class="card-header">
            <h5 class="card-title" id="exampleModalLabel">Register Details ({{ formatDate(register.opened_at) }} -
            {{ formatDate(now) }})</h5>
        </div>
        <div class="card-body">
            <h6>Method</h6>
            <table class="table table-bordered overflow-auto">
            <thead>
                <tr>
                <th scope="col">Payment Method </th>
                <th scope="col">Sale</th>
                <th scope="col">Expense</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(payment, index) in paymentMethods" :key="index">
                <td><span>{{index}}</span>{{ Object.keys(paymentMethods)[index] }}</td>
                <td>{{ formatCurrency(payment.sell) }}</td>
                <td>{{ formatCurrency(payment.expense) }}</td>
                </tr>
            </tbody>
            </table>
            <h6>Total Sales:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="ml">{{ formatCurrency(sale_data.total_sales) }}</span></h6>
            <h6>Total Refund:&nbsp;&nbsp;&nbsp;<span class="ml">{{ formatCurrency(sale_data.total_refund) }}</span></h6>
            <h6>Total Payment:<span class="ml">{{ formatCurrency(sale_data.total_payment) }}</span></h6>
            <h6>Credit Sales:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="ml">{{ formatCurrency(sale_data.credit_sales) }}</span></h6>
            <h6>Total Expense:&nbsp;<span class="ml">{{ formatCurrency(sale_data.total_expense) }}</span></h6>
            <v-divider class="mt-2"></v-divider>
            <h6>Details of products sold</h6>
            <table class="table table-bordered overflow-auto">
            <thead>
                <tr>
                <th scope="col">#</th>
                <th scope="col">SKU</th>
                <th scope="col">Product</th>
                <th scope="col">Quantity</th>
                <th scope="col">Total amount</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in soldItems" :key="index">
                <td>{{ index + 1 }}</td>
                <td>{{ item.stock_item__product__sku }}</td>
                <td>{{ item.stock_item__product__title }}</td>
                <td>{{ item.quantity }}</td>
                <td>{{ formatCurrency(item.total_amount) }}</td>
                </tr>
            </tbody>
            </table>

            <h6>Details of products sold (By Brand)</h6>
            <table class="table table-bordered overflow-auto">
            <thead>
                <tr>
                <th scope="col">#</th>
                <th scope="col">Brand</th>
                <th scope="col">Quantity</th>
                <th scope="col">Total amount</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(brand, index) in salesByBrand" :key="index">
                <td>{{ index + 1 }}</td>
                <td>{{ brand.stock_item__product__brand__title }}</td>
                <td>{{ brand.quantity }}</td>
                <td>{{ formatCurrency(brand.total_amount) }}</td>
                </tr>
            </tbody>
            </table>
            <v-divider class="mt-2 text-dark"></v-divider>
            <h6>User: <strong>{{ user.fullname }}</strong></h6>
            <h6>Email: <strong>{{ user.email }}</strong></h6>
            <h6>Business Location: <strong>{{ business.location_name }}</strong></h6>
        </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" v-if="close_register">Close Register</button>
            <button type="button" class="btn btn-primary uil uil-print text-white" @click="generatePDF">Print</button>
        </div>
    </div>
 </template>
  
  <script>
import jsPDF from 'jspdf';
import "jspdf-autotable";
import axios from "@/Axiosconfig";
import moment from 'moment'  

  export default {
    props: {
        close_register:{
            type:Boolean,
            default:false,
        }
    },
    data(){
        return{
            register: null,
            soldItems: [],
            paymentMethods:null,
            salesByBrand:[],
            sale_data:null,
            user: JSON.parse(sessionStorage.user).user,
            business: null,
            now:new Date()
        }
    },
    mounted(){
        this.business = JSON.parse(sessionStorage.business_location);
        this.user=JSON.parse(sessionStorage.user).user;
        this.updatearrays();
    },
    methods: {
      updatearrays(){
        axios.get(`pos-register/`)
        .then((response)=>{
            let data=response.data;
            console.log(data.sale_data)
            this.register=data.registerDetails;
            this.soldItems=data.sold_products;
            this.sale_data=data.sales_data;
            this.salesByBrand=data.sold_products_by_brand;
            this.paymentMethods=data.payment_methods;
        })
      },
      formatDate(dateString) {
        const date = new Date(dateString);
        return date.toLocaleString();
      },
      formatCurrency(amount) {
        return new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "KES",
        }).format(amount);
      },
      generatePDF() {
        const doc = new jsPDF();
        // Set initial y position
        let y = 15;
        // Title
        doc.setFontSize(18);
        doc.text(`Register Details(${moment(this.register.opened_at).format("DD/MM/YYYY HH:MM")} - ${moment(new Date()).format("DD/MM/YYYY HH:MM")})`, 15, y);
        y += 10;
        // Payment Method
        doc.setFontSize(14);
        doc.text("Payment Method                                    Sale                                        Expense", 15, y);
        y += 10;
        // Loop through payment methods
        Object.values(this.paymentMethods).forEach((method,index) => {
            doc.setFontSize(12);
            doc.text(`${Object.keys(this.paymentMethods)[index]}:`, 15, y);
            doc.text(`${this.formatCurrency(method.sell)}`, 100, y);
            doc.text(`${this.formatCurrency(method.expense)}`, 160, y);
            y += 10;
       });
        // Total Sales, Total Refund, Total Payment, Credit Sales
        y += 10;
        doc.text(`Total Sales:                                              ${this.formatCurrency(this.sale_data.total_sales)}`, 15, y);
        y += 10;
        doc.text(`Total Refund:                                           ${this.formatCurrency(this.sale_data.total_refund)}`, 15, y);
        y += 10;
        doc.text(`Total Payment:                                        ${this.formatCurrency(this.sale_data.total_payment)}`, 15, y);
        y += 10;
        doc.text(`Credit Sales:                                            ${this.formatCurrency(this.sale_data.credit_sales)}`, 15, y);
        y += 10;
        // Details of products sold
        y += 10;
        doc.text("Details of products sold", 15, y);
        y += 5;    
        // Loop through sold products
        let h1=['#','SKU','Product','Quantity','Amount']
        let b1 = this.soldItems.map((row,index) => [
            index,
            row.stock_item__product__sku,
            row.stock_item__product__title,
            row.quantity,
            this.formatCurrency(row.total_amount)
        ]);
        doc.autoTable({
            head: [h1],
            body: b1,
            startY: y,
            theme: 'striped',
        });
        //sold by brand
        y=doc.autoTable.previous.finalY+10;
        doc.text("Details of products sold(By Brand)", 15, y);
        let h2=['#','Brand','Quantity','Amount']
        let b2 = this.salesByBrand.map((row,index) => [
            index,
            row.stock_item__product__brand__title,
            row.quantity,
            this.formatCurrency(row.total_amount)
        ]);
        doc.autoTable({
            head: [h2],
            body: b2,
            startY: y+5,
            theme: 'striped',
        });
        y=doc.autoTable.previous.finalY+10;
        doc.text(`User: ${this.user.fullname}`,15,y)
        doc.text(`Email: ${this.user.email}`,15,y+10)
        doc.text(`Business Location: ${this.business.location_name}`,15,y+20)
        
        const previewLink=doc.output("bloburl");
        var openeddoc = window.open(
            previewLink,
            "Receipt",
            "toolbar=no,status=no,menubar=no,scrollbars=no,resizable=no,modal=yes,top=000,left=500,width=1000,height=1500"
            );
        openeddoc.focus();
      },
    },
    computed: {
    },
  };
  </script>
  <style scoped>
  .ml{
    margin-left: 50%;
  }
</style>
