<template>
  <b-container class="mt-4">
    <!-- File Upload Section -->
    <b-card>
      <b-row class="mb-3 justify-content-center">
        <b-col md="4" sm="12" class="text-center">
          <h5>Location Details</h5>
          <h6>Branch:{{ business.business__name }}({{ business.location_name }})</h6>
        </b-col></b-row
      >
      <b-row class="mb-3 justify-content-center">
        <b-col md="8" sm="12" class="text-center">
          <b-button variant="secondary" @click="downloadTemplate">
            Download Empty Template
          </b-button>
        </b-col>
        <b-col md="4" sm="12" class="text-center">
          <b-button variant="primary" :disabled="!file" @click="uploadFile">
            Upload Records
          </b-button>
        </b-col></b-row
      ></b-card
    >

    <b-row class="mb-3 justify-content-center">
      <b-col md="8" sm="12">
        <b-form-group label="Upload Excel File" label-for="file-input">
          <b-form-file
            id="file-input"
            v-model="file"
            accept=".xlsx,.xls, .csv"
            placeholder="Choose a file..."
            @change="handleFileUpload"
          ></b-form-file>
        </b-form-group>
      </b-col>
    </b-row>
    <!-- Data Table Section -->
    <b-row>
      <b-col>
        <b-table
          striped
          hover
          :items="paginatedItems"
          :fields="fields"
          responsive
          small
        ></b-table>
      </b-col>
    </b-row>

    <!-- Pagination -->
    <b-row class="mt-3 justify-content-center">
      <b-pagination
        v-model="currentPage"
        :total-rows="products.length"
        :per-page="itemsPerPage"
        aria-controls="product-table"
      ></b-pagination>
    </b-row>
  </b-container>
</template>

<script>
import axios from "@/Axiosconfig.js";
import * as XLSX from "xlsx";
import Swal from "sweetalert2";

export default {
  props: {
    business: Object,
  },
  data() {
    return {
      file: null,
      products: [], // Holds the product data after file processing
      fields: [
        "Product",
        "Variation",
        "Unit",
        "Business Location",
        "Unit Purchase Price",
        "Selling Price",
        "Current stock",
        "Product Type",
        "Category",
        "Brand",
        "Tax",
        "SKU",
        "SERIAL",
        "EXPIRY DATE",
      ],
      currentPage: 1,
      itemsPerPage: 10,
    };
  },
  computed: {
    paginatedItems() {
      // Paginate products based on the current page and items per page
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.products.slice(start, end);
    },
  },
  watch: {},
  methods: {
    uploadFile() {
      if (!this.file) return;
      const formData = new FormData();

      formData.append("fileType", "products");
      formData.append("business_name", this.business.business__name);
      formData.append("file", this.file);
      Swal.fire({
        icon:"info",
        title: "Please Wait !",
        html: "Loading data...", // add html attribute if you want or remove
        allowOutsideClick: false,
        showConfirmButton: false,
        willOpen: () => {
          Swal.showLoading();
        },
      });
      axios
        .post("uploads/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          let data=response.data;
          Swal.close()
          Swal.fire({
            icon:"success",
            title: "Success!",
            html: data.message.message, 
            allowOutsideClick: true,
            showConfirmButton: true,
            timer:3000,
          });
        })
        .catch((error) => {
          console.error("Error:", error);
          alert("Failed to upload and process the file. "+error);
        });
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (!file) return;

      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];

        // Parse data into JSON
        const rows = XLSX.utils.sheet_to_json(sheet, { header: 1 });
        const headers = rows[0]; // First row as headers
        const values = rows.slice(1); // Remaining rows as data

        // Filter out empty rows (rows where all values are empty or null)
        const nonEmptyValues = values.filter((row) =>
          row.some((cell) => cell !== null && cell !== "")
        );

        // Map imported data to defined columns
        this.products = nonEmptyValues.map((row) =>
          headers.reduce((acc, header, idx) => {
            acc[header] = row[idx] || "";
            return acc;
          }, {})
        );

        // Automatically set the first page
        this.currentPage = 1;
      };
      reader.readAsArrayBuffer(file);
    },
    downloadTemplate() {
      const columns = this.fields;
      // Create a worksheet with the columns
      const worksheetData = [columns];
      const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
      // Create a new workbook and append the worksheet
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Template");
      // Export the workbook as an Excel file
      XLSX.writeFile(workbook, "Product_Import_Template.xlsx");
    },
  },
};
</script>

<style scoped>
/* Add scrollable table container */
.b-table-wrapper {
  max-height: 400px;
  overflow-y: auto;
}
</style>
