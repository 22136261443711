<template>
  <div class="container">
    <b-form @submit.prevent="handleSubmit">
      <div class="row border-top border-3 border-primary rounded-2 bg-light mb-2">
        <div class="col-lg-4 p-1">
          <label
            >Supplier:<router-link
              class="badge badge-pill bg-primary"
              :to="{ name: 'Suppliers' }"
            >
              <i class="fa fa-plus"></i> Add New
            </router-link></label
          >
          <div class="mb-3">
            <multiselect
              v-model="purchase.supplier"
              :options="suppliers"
              :multiple="false"
              label="name"
              track-by="name"
              placeholder="Select..."
              class=""
            ></multiselect>
          </div>
        </div>
        <div class="col-lg-4 p-1">
          <label>Purchase Status:</label>
          <div class="mb-3">
            <multiselect
              v-model="purchase.purchase_status"
              :options="purchase_statuses"
              :multiple="false"
              label="title"
              track-by="title"
              placeholder="Select..."
              class=""
            ></multiselect>
          </div>
        </div>
        <div class="col-lg-4 p-1">
          <b-form-group label="Ref No">
            <b-form-input
              v-model="purchase.purchase_id"
              placeholder="Leave blank to auto generate..."
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-lg-4 p-1">
          <label>Business Location:</label>
          <div class="mb-3">
            <multiselect
              v-model="purchase.location"
              :options="locations"
              :multiple="false"
              label="name"
              track-by="name"
              placeholder="Select..."
              class=""
            ></multiselect>
          </div>
        </div>
        <div class="col-lg-4 p-1">
          <b-form-group label="Pay Term">
            <b-form-input
              v-model="purchase.pay_term.pay_duration"
              type="number"
              min="0"
              class="w-50 d-inline"
            ></b-form-input>
            <b-form-select
              v-model="purchase.pay_term.duration_type"
              :options="duration_types"
              placeholder="Select..."
              class="w-25 d-inline p-2 mb-1"
            ></b-form-select>
          </b-form-group>
        </div>
        <div class="col-lg-4 p-1">
          <b-form-group label="Attach Document">
            <b-form-file
              v-model="purchase.purchase_document"
              @change="handleFileChange"
              accept=".pdf, .doc, .docx, .jpg, .jpeg, .png"
              placeholder="Choose a file or drop it here..."
            ></b-form-file>
          </b-form-group>
        </div>
      </div>
      <div class="row border-top border-3 border-primary rounded-2 mb-2">
        <div class="col-lg-3 p-1">
          <b-form-group label="">
            <b-button class="btn btn-primary mt-4" v-b-modal.import-products-modal
              >Import Products</b-button
            >
          </b-form-group>
        </div>
        <div class="col-lg-6 p-1">
          <b-form-group label="">
            <b-input-group class="">
              <b-input-group-prepend is-text class="mt-4 mr-sm-2 d-inline-block">
                <i class="uil uil-search"></i>
              </b-input-group-prepend>
              <b-form-input
                class="mt-4 d-inline-block"
                type="search"
                placeholder="Enter Product Name / SKU / Scan Code"
                v-model="searchText"
                @input="applyFilter()"
              ></b-form-input>
            </b-input-group>
            <!-- List group with hover effect -->
            <b-list-group class="list-group-hover mr-4 w-100" v-if="showDropdown">
              <b-list-group-item
                class="list-group-item"
                v-for="item in filteredProducts"
                :key="item.id"
                @click="addToCart(item)"
              >
                {{ item.product.title }} ({{ item.product.serial || item.variation.title }}) Qty({{ item.stock_level }})
              </b-list-group-item>
            </b-list-group>
          </b-form-group>
        </div>
        <div class="col-lg-3 p-1">
          <b-form-group label="" class="mt-4 pt-2">
            <a href="#" class="uil uil-plus">Add New Product</a>
          </b-form-group>
        </div>
      </div>
      <div class="container">
        <v-card>
          <v-card-text>
            <v-data-table :headers="headers" :items="cart" item-key="id">
              <template v-slot:[`item.title`]="{ item }">
                {{ item.product.title }}
                {{ item.variation !== null ? item.variation.title : "" }}
              </template>
              <template v-slot:[`item.selling_price`]="{ item }">
                <v-text-field
                  v-model="item.selling_price"
                  type="number"
                  min="0"
                  class="w-75"
                />
              </template>
              <template v-slot:[`item.discount_amount`]="{ index, item }">
                <v-text-field
                  v-model="item.discount_amount"
                  type="number"
                  min="0"
                  class="w-50"
                  @input="calculateProfitMargin(index, $event)"
                />
              </template>
              <template v-slot:[`item.unit_price_before_tax`]="{ item }">
                <v-text-field
                  v-model="item.unit_price_before_tax"
                  type="number"
                  min="0"
                  class="w-75"
                />
              </template>
              <template v-slot:[`item.profit_margin`]="{ item }">
                <v-text-field
                  v-model="item.profit_margin"
                  type="number"
                  min="0"
                  class="w-75"
                />
              </template>
              <template v-slot:[`item.quantity`]="{ item }">
                <v-text-field
                  v-model="item.quantity"
                  type="number"
                  min="1"
                  class="w-25"
                />
              </template>
              <template v-slot:[`item.line_total`]="{ item }">{{
                new Intl.NumberFormat().format(
                  Number(item.selling_price) * Number(item.quantity)
                )
              }}</template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-btn @click="removeFromCart(item.index)" icon
                  ><v-icon>mdi-delete</v-icon></v-btn
                >
              </template>
            </v-data-table>
            <v-card class="bg-light rounded p-1">
              <div cla ss="row">
                <div class="col-sm-4 d-inline">
                  <a href="#" class="" v-b-modal.order-discount
                    ><b>Purchase Discount(-):</b><i class="uil uil-edit"></i>&nbsp;<strong
                      >{{ purchaseDiscount }}</strong
                    ></a
                  >
                  <b-modal id="order-discount" title="Order Discount" hide-footer>
                    <PurchaseDiscount @get-discount="getPurchaseDiscount" />
                  </b-modal>
                </div>
                <div class="col-sm-4 d-inline">
                  <a href="#" class="" v-b-modal.order-tax
                    ><b>Purchase Tax(+):</b><i class="uil uil-edit"></i>&nbsp;<strong>{{
                      new Intl.NumberFormat().format(purchaseTax)
                    }}</strong></a
                  >
                  <b-modal id="order-tax" title="Order Tax" hide-footer>
                    <Purchasetax @get-tax="getPurchaseTax" />
                  </b-modal>
                </div>
                <div class="col-sm-4 d-inline">
                  <a href="#" class=""
                    ><b>Shipping(+):</b><i class="uil uil-edit"></i>&nbsp;<strong
                      >0.00</strong
                    ></a
                  >
                </div>
                <div class="col-sm-4 d-inline">
                  <a href="#" class="" v-b-modal.order-service-charge
                    ><b>Packing Charge(+):</b><i class="uil uil-edit"></i>&nbsp;<strong>{{
                      purchasePackingCharge
                    }}</strong></a
                  >
                  <b-modal id="order-service-charge" title="Packing Charge" hide-footer>
                    <ServiceCharge @get-service-charge="getPurchasePackingCharge" />
                  </b-modal>
                </div>
              </div>
            </v-card>
          </v-card-text>
        </v-card>
        <v-card>
          <v-card-text>
            <div class="float-end bg-light rounded-1 p-2">
              <h4>
                Total Items: <strong>{{ totalItems }}</strong>
              </h4>
              <h4 class="text-danger">
                Net Total Amount:<strong
                  >KES {{ new Intl.NumberFormat().format(grandTotal) }}</strong
                >
              </h4>
            </div>
            <div>
              <div v-if="purchase.supplier">
              <div class="row bg-light border border-2 rounded-3 border-warning" v-if="purchase.supplier.accounts[0].account_balance>0 && supplier.user.username !=='walkin'">
              <b-form-group label="" v-slot="{ ariaDescribedby }">
                <b-form-radio class="d-inline p-2" v-model="selectedCustomerAccount" :aria-describedby="ariaDescribedby" name="customer_account" value="customer_davance">Pay From Advance Balance</b-form-radio>
                <b-form-radio class="d-inline p-2" v-model="selectedCustomerAccount" :aria-describedby="ariaDescribedby" name="customer_account" value="customer_account">Pay From Account Balance</b-form-radio>
              </b-form-group>
            </div>
            </div>
            </div>
          </v-card-text>
        </v-card>
      </div>
    </b-form>
    <div class="row">
      <b-button class="btn btn-warning w-25" @click="addPurchase()"
        >Add Purchase</b-button
      >
    </div>
    <!---modals-->
    <b-modal
      id="import-products-modal"
      ref="modal"
      title="Import Products"
      size="xl"
      class=""
    >
      <ImportProducts :business="business" />
    </b-modal>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import axios from "@/Axiosconfig.js";
import Swal from "sweetalert2";
import Purchasetax from "@/views/pages/pos/parts/Ordertax.vue";
import PurchaseDiscount from "@/views/pages/pos/parts/Discount";
import ServiceCharge from "@/views/pages/pos/parts/ServiceCharge";
import ImportProducts from "@/components/products/ImportProduct.vue";

export default {
  data() {
    return {
      headers: [
        { text: "Product Name", value: "title", sortable: true },
        { text: "Purchase Quantity", value: "quantity", sortable: true },
        { text: "Unit Cost(Before Dsct.)", value: "unit_price", sortable: true },
        { text: "Discount(%)", value: "discount_amount", sortable: true },
        { text: "Unit Cost(Before tax)", value: "unit_price_before_tax", sortable: true },
        { text: "Profit Margin %", value: "profit_margin", sortable: true },
        { text: "Unit Selling Price(Inc. tax)", value: "selling_price", sortable: true },
        { text: "Line Total", value: "line_total", sortable: true },
        { text: "Actions", value: "actions", sortable: true },
      ],
      suppliers: [],
      purchase_statuses: [
        { title: "Ordered", value: "orderd" },
        { title: "Received", value: "received" },
        { title: "Pending", value: "pending" },
      ],
      duration_types: ["Days", "Months"],
      locations: [{}],
      purchase: {
        supplier: null,
        added_by: null,
        purchase_document: null,
        pay_term: { pay_duration: 0, duration_type: "Days" },
        purchase_id: "",
        purchase_tax: 0.0,
        purchase_discount: 0.0,
        sub_total: 0.0,
        grand_total: 0.0,
        purchase_ammount: 0.0,
        location: null,
        balance_due: 0.0,
        balance_overdue: 0.0,
        date_added: null,
        date_updated: null,
        purchase_status: null,
        payment_status: "pending",
        paymethod: "cash",
        delete_status: false,
      },
      purchaseTax: 0,
      line_total: 0,
      quantity: 1,
      profit_margin: 25,
      purchaseDiscount: 0,
      purchaseShippingCharge: 0,
      purchasePackingCharge: 0,
      showDropdown: false,
      currentPage: 1,
      perPage: 4,
      limit: 4,
      offset: 0,
      business: null,
      user: null,
      filter: "",
      searchText: "",
      products: [],
      cart: [],
      filteredProducts: [],
    };
  },
  components: {
    Multiselect,
    Purchasetax,
    PurchaseDiscount,
    ServiceCharge,
    ImportProducts,
  },
  computed: {
    total() {
      return this.cart.reduce(
        (acc, item) => acc + item.unit_price_before_tax * item.quantity,
        0
      );
    },
    totalItems() {
      return this.cart.reduce((acc, item) => acc + item.quantity, 0);
    },
    myAdvanceAdvance() {
      var paid = 0;
      if (this.selectedAccount === "main_account") {
        paid=Number(this.supplier.accounts[0].account_balance);
      } else if (this.selectedCustomerAccount === "advance_account") {
        paid=paid=Number(this.supplier.accounts[0].advance_balance);
      }
      return paid;
    },
    TotalPayableBefortax() {
      return this.total - this.purchaseDiscount;
    },
    TotalPayable() {
      var t =
        this.TotalPayableBefortax +
        this.purchaseTax +
        this.purchasePackingCharge +
        this.purchaseShippingCharge;
      if (this.myAdvanceAdvance >= t) {
        return 0;
      } else {
        t -= this.myAdvanceAdvance;
      }
      return Math.ceil(t);
    },
    subTotal(){
      return this.cart.reduce(
        (total, item) => total + item.unit_price_before_tax * item.quantity,0)
    },
    grandTotal() {
      return Math.ceil(
        this.total + this.purchaseTax + this.purchasePackingCharge - this.purchaseDiscount
      );
    },
    amount_due() {
      return Math.abs(this.amountPaid - this.TotalPayable);
    },
  },
  created() {
    this.updatearrays();
  },
  mounted() {
    this.user = JSON.parse(sessionStorage.user);
    this.business = JSON.parse(sessionStorage.business_location);
  },
  methods: {
    //cart methods
    addToCart(item) {
      this.showDropdown = false;
      console.log(item);
      let product = item.product;
      var sku = (sku = item.variation ? item.variation.sku : item.product.sku);
      let quantity = this.quantity;
      let unit_price = Number(item.buying_price);
      var selling_price = Number(
        item.discount
          ? item.selling_price -
              (item.discount.discount_type == "Fixed"
                ? item.discount.discount_amount
                : item.selling_price * (item.discount.discount_amount / 100))
          : item.selling_price
      );
      let unit_price_before_tax = unit_price;
      const profit_margin = ((selling_price - unit_price) / unit_price) * 100;
      console.log(profit_margin);
      var existingItem = this.cart.find((e) => e.sku === sku);

      if (existingItem) {
        // Item already exists in the cart, update the quantity
        if (existingItem.quantity + quantity > item.stock_level) {
          this.playBeepSound(this.beep_warning_sound);
          Swal.fire({
            icon: "warning",
            title: "Low stock alert!",
            html: `<p>Item <b class='text-danger'>${sku}</b> stock level <b class='text-danger'>${
              item.stock_level
            }</b> cannot accomodate a quantity of <b>${
              existingItem.quantity + quantity
            }</b>?Please update stock first!</p>`,
            showCancelButton: true,
            cancelButtonText: "Close",
            cancelButtonColor: "#fe2413",
            showConfirmButton: false,
            timer: 5000,
          });
        } else {
          existingItem.quantity += quantity;
        }
      } else {
        // Item does not exist in the cart, add it
        this.cart.push({
          sku: sku,
          product,
          variation: item.variation,
          quantity,
          discount_amount: 0,
          unit_price,
          unit_price_before_tax,
          profit_margin,
          selling_price,
        });
        this.playBeepSound(this.beepsound);
      }
    },
    removeFromCart(index) {
      this.cart.splice(index, 1);
    },
    calculateProfitMargin(index, $event) {
      console.log(index);
      console.log($event);
      let item = this.cart[index];
      let discount_percent = Number($event);
      let discount = Number(item.unit_price * (discount_percent / 100));
      console.log(discount);
      item.unit_price_before_tax = Math.ceil(Number(item.unit_price) - discount);
      item.profit_margin +=
        Number((item.unit_price - item.unit_price_before_tax) / item.unit_price) * 100;
    },
    calculateDiscountPercent(item) {
      // Calculate discount percent based on the provided discount amount and selling price
      item.discount_percent =
        ((item.unit_price - item.discount_amount) / item.selling_price) * 100;
      // Recalculate selling price after discount
      return this.calculateSellingPrice(item);
    },
    calculateSellingPrice(item) {
      // Calculate selling price based on the provided profit margin and cost price
      item.selling_price = item.unit_price * (1 + item.profit_margin / 100);
      // Recalculate line total
      return this.calculateLineTotal(item);
    },
    updatearrays() {
      axios
        .get(`pos_stock/?filter=${this.filter}&limit=${this.limit}&offset=${this.offset}`)
        .then((response) => {
          // JSON responses are automatically parsed.
          this.products = response.data["results"];
          this.totalProducts = response.data["count"];
          this.filteredProducts = this.products;
          //fetch suppliers
          axios
            .get(
              `contacts/?location_id=${this.business.location_id}&contact_type=Suppliers`
            )
            .then((res) => {
              this.suppliers = res.data["results"].map((sup) => ({
                ...sup,
                name: `${sup.user.first_name} ${sup.user.last_name}`,
              }));
            });
          //fetch business locations
          axios
            .get(`locations/?business_name=${this.business.business__name}`)
            .then((res) => {
              this.locations = res.data["results"].map((loc) => ({
                ...loc,
                name: `${this.business.location_name} (${loc.location_id})`,
              }));
              this.purchase.location = this.locations.find(
                (l) => l.location_id === this.business.location_id
              );
            });
          // Swal.close();
        })
        .catch((e) => {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "" + e,
            showConfirmButton: true,
          }).then((e) => {
            Swal.close(e);
          });
        });
    },
    applyFilter() {
      var searchTextLower = this.searchText.toLowerCase();
      this.filter = searchTextLower;
      this.updatearrays();
      this.filteredProducts = this.products;
      this.showDropdown = true;
      var cart_item = null;
      cart_item = this.filteredProducts.find((item) => {
        let serial =
          item.product_type == "single" ? item.product.serial : item.variation.serial;
        let sku = item.product_type == "single" ? item.product.sku : item.variation.sku;
        return (
          serial.toLowerCase() === searchTextLower ||
          sku.toLowerCase() === searchTextLower ||
          null
        );
      });
      console.log(cart_item);
      if (cart_item != null) {
        this.searchText = "";
        this.addToCart(cart_item);
      }
    },
    addPurchase() {
      // Update purchase object
      this.purchase.sub_total = this.subTotal;
      this.purchase.grand_total = this.grandTotal;
      this.purchase.purchase_amount = this.TotalPayable;
      this.purchase.balance_due = this.grandTotal - (this.purchase.amount_paid || 0);
      this.purchase.date_added = new Date().toISOString(); 
      this.purchase.purhaseitems = this.cart;
      this.purchase.added_by = this.user.user.id; 
      this.purchase.location = this.purchase.location?.id || null; 
      this.purchase.purchase_status = this.purchase.purchase_status.value || "pending"; 
      this.purchase.supplier = this.purchase.supplier?.id || null; 

      // API call to submit the purchase
      axios
        .post(`purchases/`, this.purchase)
        .then((response) => {
          console.log(response.data)
          Swal.fire({
            icon: "success",
            title: "Purchase Added Successfully!",
            text: "The purchase has been added to the system.",
            timer: 3000,
          });
          this.cart = [];
        })
        .catch((err) => {
          console.error(err);
          Swal.fire({
            icon: "error",
            title: "Error!",
            text: "Failed to add the purchase. Please try again.",
            timer: 5000,
          });
        });
    },
    getPurchaseTax(tax) {
      console.log(tax);
      console.log(this.TotalPayableBefortax);
      this.purchaseTax = Math.ceil((this.TotalPayableBefortax * tax) / 100);
    },
    getPurchaseDiscount(discount) {
      console.log(discount);
      let d = 0;
      if (discount.type == "Fixed") {
        d = discount.amount;
      } else {
        d = this.total * (discount.amount / 100);
      }
      this.purchaseDiscount = Math.ceil(d);
    },
    getPurchasePackingCharge(charge) {
      console.log(charge);
      this.purchasePackingCharge = charge;
    },
    handleSubmit() {
      console.log("");
    },
  },
};
</script>
<style scoped>
.list-group-hover {
  position: absolute;
  z-index: 1000; /* Ensure it appears above other elements */
  background-color: white; /* Set the background color */
  border: 1px solid #ced4da; /* Add border for better visibility */
}

.list-group-hover .list-group-item {
  cursor: pointer; /* Change cursor to pointer on hover */
}

.list-group-hover .list-group-item:hover {
  background-color: #007bff; /* Change background color to blue on hover */
  color: white; /* Change text color to white on hover */
}
</style>
