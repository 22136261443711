import { userService } from '../../helpers/bengoboxAuth/user.service';
import router from '../../router/index'
import Swal from 'sweetalert2';

const user = JSON.parse(sessionStorage.getItem('user'));
export const state = user
    ? { status: { loggeduser: true }, user }
    : { status: {}, user: null };

export const actions = {
    // Logs in the user.
    // eslint-disable-next-line no-unused-vars
    login({ dispatch, commit }, { email, password }) {
        commit('loginRequest', { email });
        // Swal.fire({
        //     icon: "info",
        //     title: "Please Wait...!",
        //     html: "Authenticating credentials...", // add html attribute if you want or remove
        //     allowOutsideClick: false,
        //     showConfirmButton: false,
        //     willOpen: () => {
        //         Swal.showLoading();
        //     },
        // });

        userService.login(email, password)
            .then(
                user => {
                    commit('loginSuccess', user);
                    console.log(user)
                    if(user.user.permissions.includes('view_sales')){
                        router.push('/pos/index')
                    }else{
                    router.push('/pos/index')
                    }
                    //Swal.close();
                },
                error => {
                    commit('loginFailure', error);
                    dispatch('notification/error', error, { root: true });
                    console.log(error);
                }
        )
    },
    // Logout the user
    logout({ commit }) {
        userService.logout();
        commit('logout');
    },
    // register the user
    registeruser({ dispatch, commit }, user) {
        commit('registerRequest', user);
        console.log(user)
        // Swal.fire({
        //     icon: "info",
        //     title: "Please Wait...!",
        //     html: "Verifying account details...", // add html attribute if you want or remove
        //     allowOutsideClick: false,
        //     showConfirmButton: false,
        //     willOpen: () => {
        //         Swal.showLoading();
        //     },
        // });
        userService.register(user)
            .then(
                user => {
                    commit('registerSuccess', user);
                    dispatch('notification/success', 'Registration successful', { root: true })
                        .then(() => {
                            Swal.fire({
                                icon: 'info',
                                title: 'Account registration succesful!',
                                html: `<p>A confirmation email has been sent to your email,<a href='${user.email}' class='text-primary'>${user.email}</a><br />Please login to confirm your email before you can proceed to login!</p>`,
                                showCancelButton:true,
                            }).then(() => {
                                router.push('/login');
                            })
                        })
                    //Swal.close();
                },
                error => {
                    commit('registerFailure', error);
                    dispatch('notification/error', error, { root: true });
                }
            );
    }
};

export const mutations = {
    loginRequest(state, user) {
        state.status = { loggingIn: true };
        state.user = user;
    },
    loginSuccess(state, user) {
        state.status = { loggeduser: true };
        state.user = user;
        sessionStorage.setItem('user', JSON.stringify(user))
    },
    loginFailure(state) {
        state.status = {};
        state.user = null;
    },
    logout(state) {
        state.status = {};
        state.user = null;
    },
    registerRequest(state) {
        state.status = { registering: true };
    },
    registerSuccess(state) {
        state.status = {};
    },
    registerFailure(state) {
        state.status = {};
    }
};

