<template>
    <div class="container">
        <b-form @submit.prevent="handleSubmit">
        <div class="row border-top border-3 border-primary rounded-2 bg-light mb-2">
            <div class="col-lg-4 p-1">
                <label>Business Location:</label>
                <div class="mb-3">
                <multiselect
                    v-model="adjustment.location"
                    :options="locations"
                    :multiple="false"
                    label="name"
                    track-by="name"
                    placeholder="Select..."
                    class=""
                ></multiselect>
                </div>
            </div>
            <div class="col-lg-4 p-1">
                <b-form-group label="Ref No">
                <b-form-input v-model="adjustment.ref_no" placeholder="Leave blank to auto generate..."></b-form-input>
                </b-form-group>
            </div>
            <div class="col-lg-4 p-1">
                <label>Adjustment Type:</label>
                <div class="mb-3">
                <multiselect
                    v-model="adjustment.adjustment_type"
                    :options="adjustment_types"
                    :multiple="false"
                    label="title"
                    track-by="title"
                    placeholder="Select..."
                    class=""
                ></multiselect>
                </div>
            </div>
        </div>
        <div class="row border-top border-3 border-primary rounded-2 mb-2">
            <div class="col-lg-6 p-1">
              <b-form-group label="">
                <b-input-group class="">
                <b-input-group-prepend is-text class="mt-4 mr-sm-2 d-inline-block">
                    <i class="uil uil-search"></i>
                </b-input-group-prepend>
                <b-form-input 
                class="mt-4 d-inline-block"
                type="search"
                placeholder="Enter Product Name / SKU / Scan Code"
                v-model="searchText"
                @input="applyFilter()"
                ></b-form-input>
              </b-input-group>
            <!-- List group with hover effect -->
            <b-list-group 
              class="list-group-hover mr-4 w-100"
              v-if="showDropdown"
            >
              <b-list-group-item  class="list-group-item"
                v-for="item in filteredProducts" 
                :key="item.id" 
                @click="addToCart(item)"
              >
                {{ item.product.title }} ({{ item.product.sku || item.variation.title }})
              </b-list-group-item>
            </b-list-group>
            </b-form-group>
            </div>
        </div>
        <div class="container">
        <v-card>
        <v-card-text>
          <v-data-table :headers="headers" :items="cart" item-key="id">
            <template v-slot:[`item.title`]="{ item }">
              <p>{{ item.product.title }} {{ item.variation !== null ? item.variation.title : '' }}</p>
              <small class="text-muted">Current Stock: {{ item.stock_level }}&nbsp;{{ item.unit }}</small>
            </template>
            <template v-slot:[`item.quantity`]="{ item }">
              <v-text-field v-model="item.quantity" type="number" min="1" class="w-25" />
            </template>
            <template v-slot:[`item.unit_price`]="{ item }">
              <v-text-field v-model="item.unit_price" type="number" min="1" class="w-25" />
            </template>
            <template v-slot:[`item.sub_total`]="{ item }">
              {{ item.unit_price*item.quantity }}
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn @click="removeFromCart(item.index)" icon
                ><v-icon>mdi-delete</v-icon></v-btn
              >
            </template>
          </v-data-table>
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                  <label for="amount_recovered">Total Recovered</label>
                  <input type="number" class="form-control w-50" id="amount_recovered" aria-describedby="Help" :placeholder="0" v-model="adjustment.total_recovered">
                  <small id="Help" class="form-text text-muted">Total recovered fron insurance, selling item scrap or others</small>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                  <label for="reason">Reason</label>
                  <input type="text" class="form-control w-75" id="reason" aria-describedby="reasonHelp" placeholder="reason..." v-model="adjustment.reason">
                  <small id="reasonHelp" class="form-text text-muted">Reaso for adjsutment</small>
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
      <v-card>
        <v-card-text>
          <div class="float-end bg-light rounded-1 p-2">
            <h4 class="text-danger">Total Amount:<strong>KES {{ new Intl.NumberFormat().format(total)}}</strong></h4>
          </div>
          <div >
          </div>
        </v-card-text>
      </v-card>
        </div>
      </b-form>
      <div class="row">
          <b-button class="btn btn-secondary w-25" @click="addadjustment()">Save</b-button>
        </div>
    </div>
  </template>
  
  <script>
 import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import axios from "@/Axiosconfig.js";
import Swal from "sweetalert2";

export default {
  data() {
    return {
        headers: [
        { text: "Product", value: "title", sortable: true },
        { text: "Quantity", value: "quantity", sortable: true },
        { text: "Unit Price", value: "unit_price", sortable: true },
        { text: "Sub Total", value: "sub_total", sortable: true },
        { text: "Actions", value: "actions", sortable: true },
      ],
      suppliers:[],
      adjustment_types:[
        {'title':'Increase','value':'increase'},
        {'title':'Decrease','value':'decrease'},
      ],
      locations:[{}],
      adjustment: {
        added_by: null,
        total_recovered:0.00,
        items:this.cart,
        ref_no: '',
        reason:'',
        location:null,
        date_added: null,
        date_updated: null,
        adjustment_type: {'title':'Increase','value':'increase'},
        delete_status: false
      },
      quantity:1,
      showDropdown: false,
      currentPage: 1,
      perPage: 4,
      limit: 4,
      offset: 0,
      business:null,
      filter: "",
      searchText: "",
      products: [],
      cart: [],
      user:null,
      filteredProducts: [],
    };
  },
  components:{
    Multiselect,
  },
  computed: {
    total() {
      return this.cart.reduce((acc, item) => acc + item.unit_price* item.quantity, 0);
    },
  },
  created(){
    this.updatearrays();
  },
  mounted() {
    this.user = JSON.parse(sessionStorage.user);
    this.business=JSON.parse(sessionStorage.business_location);
  },
  methods: {
    //cart methods
    addToCart(item) {
      this.showDropdown=false;
      console.log(item)
      let product = item.product;
      var sku=sku=item.variation ? item.variation.sku : item.product.sku
      let quantity = this.quantity;
      let unit_price=Number(item.buying_price);
      var existingItem = this.cart.find(e => e.sku === sku);
      var stock_level=item.stock_level;
      var unit=item.unit.title;

    if (existingItem) {
        // Item already exists in the cart, update the quantity
        if((existingItem.quantity+quantity)>item.stock_level){
          this.playBeepSound(this.beep_warning_sound);
          Swal.fire({
            icon:"warning",
            title:'Low stock alert!',
            html:`<p>Item <b class='text-danger'>${sku}</b> stock level <b class='text-danger'>${item.stock_level}</b> cannot accomodate a quantity of <b>${existingItem.quantity+quantity}</b>?Please update stock first!</p>`,
            showCancelButton: true,
            cancelButtonText:'Close',
            cancelButtonColor:'#fe2413',
            showConfirmButton:false,
            timer:5000,
          })
        }else{
        existingItem.quantity += quantity;
        }
    } else {
        // Item does not exist in the cart, add it
        this.cart.push({
            sku: sku,
            product,
            variation:item.variation,
            quantity,
            unit_price,
            stock_level,
            unit,
        });
        this.playBeepSound(this.beepsound);
    }
    },
    removeFromCart(index) {
      this.cart.splice(index, 1);
    },
    updatearrays() {
      const cacheKey = 'pos_stock_cache';
      const cachedData = JSON.parse(localStorage.getItem(cacheKey));
      localStorage.removeItem(cacheKey)

      const currentFilter = this.filter;
      const currentLimit = this.limit;
      const currentOffset = this.offset;

      const cacheExpiration = 60 * 60 * 1000; // 1 hour in milliseconds

      // Check if cached data exists and is not expired
      if (cachedData && cachedData.timestamp && (Date.now() - cachedData.timestamp < cacheExpiration)) {
          // Use cached data if filter, limit, and offset are unchanged
          if (cachedData.filter === currentFilter && cachedData.limit === currentLimit && cachedData.offset === currentOffset) {
              this.products = cachedData.data.results;
              this.totalProducts = cachedData.data.count;
              this.filteredProducts = this.products;
              this.locations = cachedData.locations;
              this.purchase.location = this.locations.find(l => l.location_id === this.business.location_id);
              return;
          }
      }

      // Fetch data from server if no cached data or if filter, limit, or offset has changed
      axios.get(`pos_stock/?filter=${currentFilter}&limit=${currentLimit}&offset=${currentOffset}`)
        .then((response) => {
            // JSON responses are automatically parsed.
            this.products = response.data.results;
            this.totalProducts = response.data.count;
            this.filteredProducts = this.products;

            // Fetch business locations
            axios.get(`locations/?business_name=${this.business.business__name}`)
                .then((res) => {
                    this.locations = res.data.results.map(loc => ({
                        ...loc,
                        name: `${this.business.business__name} (${loc.location_id})`
                    }));
                    this.purchase.location = this.locations.find(l => l.location_id === this.business.location_id);
                });

            // Cache data
            const dataToCache = {
                filter: currentFilter,
                limit: currentLimit,
                offset: currentOffset,
                data: response.data,
                locations: this.locations,
                timestamp: Date.now()
            };
            localStorage.setItem(cacheKey, JSON.stringify(dataToCache));
        })
        .catch((e) => {
            Swal.fire({
                position: "center",
                icon: "error",
                title: "" + e,
                showConfirmButton: true,
            }).then((e) => {
                Swal.close(e);
            });
        });
    },
    applyFilter() {
      var searchTextLower = this.searchText.toLowerCase();
      this.filter = searchTextLower;
      this.updatearrays();
      this.filteredProducts = this.products;
      this.showDropdown=true;
      var cart_item=null;
      cart_item=this.filteredProducts.find((item) => {
        let serial=item.product_type=='single'?item.product.serial:item.variation.serial
        let sku=item.product_type=='single'?item.product.sku:item.variation.sku
        return (
          serial.toLowerCase()===searchTextLower ||
          sku.toLowerCase()===searchTextLower
        )||null;
      });
      console.log(cart_item)
      if(cart_item !=null){
        this.searchText=''
        this.addToCart(cart_item)
      }
    },
    addadjustment() {
      // Reset the form fields after modal is hidden
      this.adjustment['items']=this.cart;
      this.adjustment.added_by=this.user.user.id;
      this.adjustment['location']=this.adjustment.location.id;
      this.adjustment.adjustment_type=this.adjustment.adjustment_type.value
      let data = this.adjustment;
      axios.post(`stockadjustments/`,data)
      .then((response) => {
        console.log(response.data)
        Swal.fire({
          icon:'success',
          title:'Success',
          html:'Stock adjustment added successfully!',
          timer:3000,
        })
        this.updatearrays()
      }).catch((err) => {
        console.log(err);
        Swal.fire({
            icon:'error',
            title:'Error!',
            html:err,
            timer:5000,
        });

      });
    },
    handleSubmit() {
        console.log('');
    }
  }
};
</script>
<style scoped>
.list-group-hover {
  position: absolute;
  z-index: 1000; /* Ensure it appears above other elements */
  background-color: white; /* Set the background color */
  border: 1px solid #ced4da; /* Add border for better visibility */
}

.list-group-hover .list-group-item {
  cursor: pointer; /* Change cursor to pointer on hover */
}

.list-group-hover .list-group-item:hover {
  background-color: #007bff; /* Change background color to blue on hover */
  color: white; /* Change text color to white on hover */
}

</style>