<template>
  <div class="conatiner">
    <v-card class="border border-solid border-2 rounded-1 border-warning">
      <v-card-title>
        <div class="row">
          <div class="col-sm-6">
            <p class="">
              {{ business.business__name }}({{ business.city }} -
              {{ business.location_id }})
            </p>
          </div>
          <div class="col-sm-6">
            <div class="row">
              <div class="col-sm-4">
                <a
                  href="#"
                  class="uil uil-minus-circle text-white rounded-1 p-1 bg-info my-5"
                  v-b-modal.modal-expense
                  >&nbsp;Add Expense</a
                >
              </div>
              <div class="col-sm-3 m-0">
                <a
                  href="#"
                  class="uil uil-pause-circle text-white text-center rounded-1 p-1 bg-warning m-1"
                  title="Suspended Sales"
                  >&nbsp;</a
                >
                <a
                  href="#"
                  class="uil uil-briefcase text-white text-center rounded-1 p-1 bg-primary m-1"
                  v-b-modal.modal-register
                  title="Cash Register"
                  >&nbsp;</a
                >
                <a
                  href="#"
                  class="uil uil-x text-white text-center rounded-1 p-1 bg-danger m-1"
                  @click="closeRegister(false)"
                  title="Close Register"
                  >&nbsp;</a
                >
              </div>
            </div>
          </div>
        </div>
      </v-card-title>
      <div class="row m-2 p-2">
        <div class="col-lg-4">
          <div class="mb-3">
            <multiselect
              v-model="customer"
              :options="customerData"
              :multiple="false"
              :searchable="true"
              :remote-search="searchCustomers"
              :loading="isLoading"
              placeholder="Search a customer"
              label="fullName"
              track-by="id"
              :close-on-select="true"
              @search-change="searchCustomers"
            ></multiselect>
          </div>
          <p v-if="!customer.user.username.includes('walkin')">
            <span class="text-danger"
              >Account Balance:KShs.<strong>{{
                new Intl.NumberFormat().format(customer.accounts[0].account_balance)
              }}</strong></span
            ><br /><span class="text-danger"
              >Advance Balance:KShs.<strong>{{
                new Intl.NumberFormat().format(customer.accounts[0].advance_balance)
              }}</strong></span
            >
          </p>
        </div>
        <div class="col-lg-4">
          <!-- <div id="interactive" class="viewport"><button @click="beepOnScan()">play</button></div> -->
          <b-form-group label="">
            <b-input-group class="">
              <b-input-group-prepend is-text class="mb-4 mr-sm-2 d-inline-block">
                <i class="uil uil-search"></i>
              </b-input-group-prepend>
              <b-form-input
                class="mb-4 mr-sm-2 d-inline-block"
                type="search"
                placeholder="Enter Product Name / SKU / Scan Code"
                v-model="searchText"
                @input="applyFilter()"
              ></b-form-input>
            </b-input-group>
          </b-form-group>
        </div>
        <div class="col-lg-2">
          <div class="mb-3">
            <button
              class="btn btn-outline-warning my-2 my-sm-0 d-inline-block"
              type="button"
              @click="applyFilter()"
            >
              <i class="fa fa-sync"></i>Refresh
            </button>
          </div>
        </div>
        <div class="col-sm-2 float-end">
          <span class="p-2 rounded-1 bg-danger d-inline-block"></span>
          <span class="d-inline-block text-danger p-2">Stock alert!!</span>
        </div>
      </div>
      <div class="row m-2 overflow-scroll border border-2 border-light rounded">
        <div class="col-sm-2 p-2" v-for="item in filteredProducts" :key="item.id">
          <a @click="addToCart(item)" class="bg-light" href="#">
            <figure>
              <img
                :src="item.product.images[0].image"
                :width="200"
                :height="150"
                alt="Product Image"
              />
              <figcaption width="100%">
                <p
                  class="badge badge-pill bg-secondary text-wrap"
                  :class="{
                    'bg-danger': Number(item.stock_level) < Number(item.reorder_level),
                  }"
                >
                  {{ getFirstTwoWords(item.product.title) }} sku:{{
                    item.product.sku || item.variation.sku
                  }}
                  <span class="bg-warning p-1 rounded-3" v-if="item.variation !== null"
                    >{{ item.variation.title }}&nbsp;{{ item.unit.title }}</span
                  >&nbsp;&nbsp;
                  <span class="bg-warning p-1 rounded-3 m-0"
                    >QTY:{{ item.stock_level }}</span
                  >
                </p>
              </figcaption>
            </figure>
          </a>
        </div>
        <div class="row mb-1">
          <v-pagination
            v-if="totalProducts > 1"
            v-model="currentPage"
            :total-visible="8"
            :prev-text="'Previous'"
            :next-text="'Next'"
            :length="Math.ceil(totalProducts / perPage)"
            @input="handlePageChange(currentPage)"
            class="bg-warning text-dark rounded-1"
          ></v-pagination>
        </div>
      </div>
      <v-card id="tray">
        <v-card-title>Tray</v-card-title>
        <v-card-text>
          <v-data-table :headers="headers" :items="cart" item-key="id">
            <template v-slot:[`item.image`]="{ item }"
              ><img :src="item.product.images[0].image" :height="30"
            /></template>
            <template v-slot:[`item.title`]="{ item }"
              >{{ item.product.title }}
              {{ item.variation !== null ? item.variation.title : "" }}</template
            >
            <template v-slot:[`item.selling_price`]="{ item }">{{
              new Intl.NumberFormat().format(item.selling_price)
            }}</template>
            <template
              v-slot:[`item.profit_margin`]="{ item }"
              v-if="user.user.permissions.includes('change_discounts')"
              >{{ new Intl.NumberFormat().format(item.profit_margin) }}</template
            >
            <template v-slot:[`item.quantity`]="{ item }">
              <v-text-field
                v-model="item.quantity"
                type="number"
                min="1"
                class="w-25"
                :disabled="item.stock_level - item.quantity === 0"
              />
            </template>
            <template v-slot:[`item.available_quantity`]="{ item }">
              {{ item.stock_level - item.quantity }}
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn @click="removeFromCart(item.index)" icon
                ><v-icon>mdi-delete</v-icon></v-btn
              >
            </template>
          </v-data-table>
          <v-card class="bg-light rounded p-1">
            <v-card class="bg-light rounded p-1">
              <div class="row float-end mb-4 mt-0 p-2">
                <a href="#tray" class="uil uil-cancel text-danger" @click="cart = []"
                  >Clear Tray</a
                >
              </div>
            </v-card>
            <div cla ss="row">
              <div class="col-sm-4 d-inline">
                <a href="#tray" class="" v-b-modal.order-discount
                  ><b>Discount(-):</b><i class="uil uil-edit"></i>&nbsp;<strong>{{
                    orderDiscount
                  }}</strong></a
                >
                <b-modal id="order-discount" title="Order Discount" hide-footer>
                  <OrderDiscount @get-discount="getOrderDiscount" />
                </b-modal>
              </div>
              <div class="col-sm-4 d-inline">
                <a href="#" class="" v-b-modal.order-tax
                  ><b>Order Tax(+):</b><i class="uil uil-edit"></i>&nbsp;<strong>{{
                    new Intl.NumberFormat().format(orderTax)
                  }}</strong></a
                >
                <b-modal id="order-tax" title="Order Tax" hide-footer>
                  <Ordertax @get-tax="getOrderTax" />
                </b-modal>
              </div>
              <div class="col-sm-4 d-inline">
                <a href="#" class=""
                  ><b>Shipping(+):</b><i class="uil uil-edit"></i>&nbsp;<strong
                    >0.00</strong
                  ></a
                >
              </div>
              <div class="col-sm-4 d-inline">
                <a href="#" class="" v-b-modal.order-service-charge
                  ><b>Packing Charge(+):</b><i class="uil uil-edit"></i>&nbsp;<strong>{{
                    orderPackingCharge
                  }}</strong></a
                >
                <b-modal id="order-service-charge" title="Packing Charge" hide-footer>
                  <ServiceCharge @get-service-charge="getOrderPackingCharge" />
                </b-modal>
              </div>
            </div>
          </v-card>
        </v-card-text>
      </v-card>
      <v-card>
        <v-card-text>
          <div class="float-end bg-light rounded-1 p-2">
            <h3>
              Total Payable: KES
              <strong>{{
                new Intl.NumberFormat().format(TotalPayable.toFixed(2))
              }}</strong>
            </h3>
            <small class="text-danger"
              >Grand Total:<strong
                >KES {{ new Intl.NumberFormat().format(grandTotal) }}</strong
              ></small
            >
            <h4 v-if="amountPaid > 0">
              Balance: KES
              <strong>{{ new Intl.NumberFormat().format(change.toFixed(2)) }}</strong>
            </h4>
          </div>
          <div>
            <h3 class="d-inline">Tendered Amount:</h3>
            <v-text-field
              v-model.number="amountPaid"
              :placeholder="amountPaid"
              class="d-inline-block form-input m-2"
            ></v-text-field>
            <div
              class="row bg-light border border-2 rounded-3 border-warning"
              v-if="!customer.user.username.includes('walkin')"
            >
              <b-form-group
                label=""
                v-slot="{ ariaDescribedby }"
                v-if="customer.accounts[0].account_balance > 0"
              >
                <b-form-radio
                  class="d-inline p-2"
                  v-model="selectedCustomerAccount"
                  :aria-describedby="ariaDescribedby"
                  name="customer_advance"
                  value="customer_advance"
                  >Pay From Advance Balance</b-form-radio
                >
                <b-form-radio
                  class="d-inline p-2"
                  v-model="selectedCustomerAccount"
                  :aria-describedby="ariaDescribedby"
                  name="customer_account"
                  value="customer_account"
                  >Pay From Account Balance</b-form-radio
                >
              </b-form-group>
            </div>
            <div class="row">
              <div class="col-lg-2 col-md-3 col-sm-2">
                <div>
                  <label class="card-radio-label">
                    <input
                      type="radio"
                      name="pay-method"
                      id="pay-methodoption2"
                      v-model="paymentMethod"
                      value="Cash"
                      class="card-radio-input"
                    />
                    <span
                      class="card-radio text-center text-truncate bg-light border border-1 border-light"
                      @click="confirmPayment('Paid', 'Cash')"
                    >
                      <i class="uil uil-bill d-block text-success"></i>Cash
                    </span>
                  </label>
                </div>
              </div>
              <div class="col-lg-2 col-md-3 col-sm-2">
                <a href="#" v-b-modal.modal-mpesa>
                  <div>
                    <label class="card-radio-label">
                      <input
                        type="radio"
                        name="pay-method"
                        id="pay-methodoption2"
                        v-model="paymentMethod"
                        value="Mpesa"
                        class="card-radio-input"
                      />
                      <span
                        class="card-radio text-center text-truncate border border-1 border-light"
                      >
                        <img
                          :src="require('@/assets/img/shop/mpesa.png')"
                          width="50"
                          height="45"
                        />
                      </span>
                    </label>
                  </div>
                </a>
              </div>
              <div class="col-lg-2 col-md-3 col-sm-2">
                <div data-toggle="collapse">
                  <label class="card-radio-label">
                    <input
                      type="radio"
                      name="pay-method"
                      id="pay-methodoption1"
                      v-model="paymentMethod"
                      value="Card"
                      class="card-radio-input"
                      required
                    />
                    <span
                      class="card-radio text-center text-truncate bg-light border border-1 border-light"
                    >
                      <i class="uil uil-postcard d-block text-success"></i>
                      Credit / Debit Card
                    </span>
                  </label>
                </div>
              </div>
              <div class="col-lg-1 col-md-2 col-sm-2">
                <div data-toggle="collapse">
                  <label class="card-radio-label">
                    <input
                      type="radio"
                      name="pay-method"
                      id="pay-methodoption1"
                      v-model="paymentMethod"
                      value="Pending"
                      class="card-radio-input"
                      required
                    />
                    <span
                      class="card-radio text-center text-truncate bg-danger border border-1 border-light"
                      @click="confirmPayment('Pending', 'Pending')"
                    >
                      <i class="fa fa-pause d-block text-white"></i>
                      Suspend
                    </span>
                  </label>
                </div>
              </div>
              <div class="col-lg-1 col-md-2 col-sm-2">
                <div data-toggle="collapse">
                  <label class="card-radio-label">
                    <input
                      type="radio"
                      name="pay-method"
                      id="pay-methodoption1"
                      v-model="paymentMethod"
                      value="Credit"
                      class="card-radio-input"
                      required
                    />
                    <span
                      class="card-radio text-center text-truncate bg-warning border border-1 border-light"
                      @click="confirmPayment('Due', 'Credit')"
                    >
                      <i class="fa fa-check-circle d-block text-white"></i>
                      Credit Sale
                    </span>
                  </label>
                </div>
              </div>
              <div class="col-lg-1 col-md-2 col-sm-2">
                <div data-toggle="collapse">
                  <label class="card-radio-label">
                    <input
                      type="radio"
                      name="pay-method"
                      id="pay-methodoption1"
                      v-model="paymentMethod"
                      value="Quotation"
                      class="card-radio-input"
                      required
                    />
                    <span
                      class="card-radio text-center text-truncate bg-info border border-1 border-light"
                      @click="confirmPayment('Quotation', 'Quotation')"
                    >
                      <i class="fa fa-question-circle d-block text-white"></i>
                      Quotation
                    </span>
                  </label>
                </div>
              </div>
              <div class="col-lg-1 col-md-2 col-sm-2">
                <div data-toggle="collapse">
                  <label class="card-radio-label">
                    <input
                      type="radio"
                      name="pay-method"
                      id="pay-methodoption1"
                      v-model="paymentMethod"
                      value="Draft"
                      class="card-radio-input"
                      required
                    />
                    <span
                      class="card-radio text-center text-truncate bg-light border border-1 border-success"
                      @click="confirmPayment('Draft', 'Draft')"
                    >
                      <i class="fa fa-file d-block text-dark"></i>
                      Draft
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-card>
    <!--modal mpesa payment-->
    <b-modal title="Confirm Payment Details" size="lg" id="modal-card">
      <form @submit.prevent="handleSubmit()">
        <div class="form-group">
          <p class="text-muted">
            An M-Pesa stk push will be sent to your phone when you tap
            <b>REQUEST PAYMENT</b>
            button. Please confirm your pin on the pop-up screen to complete your order!
          </p>
          <label for="amount" class="mr-4">Amount To Be Paid:(KES)</label>
          <span class="d-inline float-right mt-0 pt-0 mb-2">
            <img :src="require('@/assets/img/shop/mpesa.png')" width="90" height="50" />
          </span>
          <span class="d-inline p-2"
            ><strong class="muted">{{
              new Intl.NumberFormat().format(TotalPayable.toFixed(2))
            }}</strong></span
          >
        </div>
        <div class="form-group">
          <label for="phone">Phone Number:</label>
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              id="phone"
              v-model="PhoneNumber"
              :disabled="!editablePhoneNumber"
            />
            <div class="input-group-append">
              <button
                class="btn btn-outline-secondary"
                type="button"
                @click="editablePhoneNumber = true"
              >
                Edit
              </button>
            </div>
          </div>
        </div>
        <button
          type="submit"
          class="btn btn-primary text-white mt-2 text-uppercase"
          @click="placeOrder()"
        >
          make payment
        </button>
      </form>
    </b-modal>
    <b-modal
      id="modal-mpesa"
      ref="modal"
      title="Confirm Payment"
      size="lg"
      v-model="mpesamodalOpen"
    >
      <form ref="form" @submit.prevent="handleSubmit()">
        <div class="form-group">
          <p class="text-muted">
            An M-Pesa stk push will be sent to your phone when you tap
            <b>REQUEST PAYMENT</b>
            button. Please confirm your pin on the pop-up screen to complete your order!
          </p>
          <label for="amount" class="mr-4">Amount:(KES)</label>
          <span class="d-inline float-right mt-0 pt-0 mb-2">
            <img :src="require('@/assets/img/shop/mpesa.png')" width="90" height="50" />
          </span>
          <h3 class="d-inline p-2" id="amount">
            <strong class="muted">{{
              new Intl.NumberFormat().format(TotalPayable.toFixed(2))
            }}</strong>
          </h3>
        </div>
        <div class="form-group">
          <label for="phone">Phone Number:</label>
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              id="phone"
              v-model="PhoneNumber"
              :disabled="!editablePhoneNumber"
            />
            <div class="input-group-append">
              <button
                class="btn btn-outline-secondary"
                type="button"
                @click="editablePhoneNumber = true"
              >
                Edit
              </button>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <b-button
              typ="button"
              @click="confirmPayment('Pending', 'Mpesa')"
              variant="success"
              >Request Payment
            </b-button>
            <b-button
              typ="button"
              @click="startCountdown()"
              variant="success"
              :disabled="countdown > 0"
              v-if="countdown == 0"
              >Confirm Payment {{ countdown }}s
            </b-button>
          </div>
          <div class="col-lg-6">
            <b-button
              typ="button"
              @click="confirmPayment('Pending', 'Pending')"
              variant="danger"
              class="fa fa-pause"
              >&nbsp;Suspend
            </b-button>
          </div>
        </div>
      </form>
    </b-modal>
    <b-modal id="modal-recents" ref="modal" title="Recent Transactions" size="lg">
      <!-- Modal Body -->
      <div class="modal-body">
        <Recents />
      </div>
    </b-modal>
    <Receipt @printReceipt="fetchReceiptData" v-show="false" :sale="receiptData" />
    <b-modal title="Print Receipt?" size="sm" id="modal-print" hide-footer>
      <div class="container text-center">
        <a
          href="#"
          class="uil uil-print text-secondary fa-2x bg-light"
          @click="fetchReceiptData()"
        ></a>
      </div>
    </b-modal>
    <!--Add expense-->
    <b-modal
      id="modal-expense"
      ref="modal-expense"
      title="Add Expense"
      size="xl"
      class=""
      hide-footer
    >
      <AddExpense />
    </b-modal>
    <!--Sale Register-->
    <b-modal
      id="modal-register"
      ref="modal-register"
      title="Sale Register"
      size="xl"
      class=""
      hide-footer
    >
      <SaleRegister />
    </b-modal>
    <!-- Floating button -->
    <div class="floating-button">
      <a class="btn btn-primary uil uil-clock p-2" v-b-modal.modal-recents href="#"
        >Recent Transactions</a
      >
    </div>
  </div>
</template>
<script>
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import Receipt from "./printReceipt.vue";
import Ordertax from "./parts/Ordertax.vue";
import OrderDiscount from "./parts/Discount";
import ServiceCharge from "./parts/ServiceCharge";
import Recents from "@/views/pages/pos/parts/Recents.vue";
import axios from "@/Axiosconfig.js";
import Swal from "sweetalert2";
import AddExpense from "@/views/pages/expenses/parts/AddExpense.vue"; // Import the AddExpense component
import SaleRegister from "@/views/pages/pos/register/register.vue"; // Import the AddExpense component

export default {
  name: "PosCart",
  data() {
    return {
      headers: [
        { text: "Image", value: "image", sortable: true },
        { text: "Title", value: "title", sortable: true },
        { text: "Price", value: "selling_price", sortable: true },
        { text: "Quantity", value: "quantity", sortable: true },
        { text: "Available Quantity", value: "available_quantity", sortable: true },
        { text: "Actions", value: "actions", sortable: true },
      ],
      receiptHeaders: [
        { text: "Product", value: "product.title", sortable: true },
        { text: "Variation", value: "variation.title", sortable: true },
        { text: "Quantity", value: "quantity", sortable: true },
        { text: "Unit Price", value: "unit_price", sortable: true },
        { text: "Subtotal", value: "sub_total", sortable: true },
      ],
      currentPage: 1,
      perPage: 12,
      limit: 12,
      offset: 0,
      customerData: [],
      receiptData: null,
      customer: null,
      filter: "",
      searchText: "",
      query: "",
      products: [],
      cart: [],
      filteredProducts: [],
      totalProducts: 0,
      quantity: 1,
      orderTax: 0,
      orderDiscount: 0,
      orderShippingCharge: 0,
      orderPackingCharge: 0,
      paymentMethod: "Cash",
      amountPaid: 0,
      selectedCustomerAccount: "",
      transactionCompleted: false,
      paycode: "NULL",
      trstatus: "Pending",
      myid: "",
      business: null,
      editablePhoneNumber: false,
      isLoading: false,
      PhoneNumber: "0743793901",
      beepsound: require("@/assets/audio/beep.mp3"),
      beep_warning_sound: require("@/assets/audio/beep-warning.mp3"),
      beepConfirmSound: require("@/assets/audio/confirm.mp3"),
      countdown: 15,
      mpesamodalOpen: false,
      mpesaPassword: null,
      mpesaCheckoutID: null,
      mpesaLipaTime: null,
      sale_data: null,
      registerOpen: false,
      user: null,
    };
  },
  components: {
    Multiselect,
    Receipt,
    Ordertax,
    OrderDiscount,
    ServiceCharge,
    Recents,
    AddExpense,
    SaleRegister,
  },
  computed: {
    customers() {
      return this.customerData;
    },
    total() {
      return this.cart.reduce((acc, item) => acc + item.selling_price * item.quantity, 0);
    },
    customerAdvance() {
      var paid = 0;
      if (this.selectedCustomerAccount === "customer_account") {
        paid = Number(this.customer.accounts[0].account_balance);
      } else if (this.selectedCustomerAccount === "customer_advance") {
        paid = paid = Number(this.customer.accounts[0].advance_balance);
      }
      return paid;
    },
    TotalPayableBefortax() {
      return this.total - this.orderDiscount;
    },
    TotalPayable() {
      var t =
        this.TotalPayableBefortax +
        this.orderTax +
        this.orderPackingCharge +
        this.orderShippingCharge;
      if (this.customerAdvance >= t) {
        return 0;
      } else {
        t -= this.customerAdvance;
      }
      return Math.ceil(t);
    },
    grandTotal() {
      return Math.ceil(
        this.total + this.orderTax + this.orderPackingCharge - this.orderDiscount
      );
    },
    change() {
      return this.amountPaid >= this.TotalPayable
        ? this.amountPaid - this.TotalPayable
        : 0;
    },
    amount_due() {
      return Math.abs(this.amountPaid - this.TotalPayable);
    },
    hiddenPhone() {
      // Replace the first 4 characters with x
      const hiddenChars = this.PhoneNumber.substr(0, 4).replace(/./g, "x");
      // Return the hidden text with the last 4 digits
      return hiddenChars + "-" + this.PhoneNumber.substr(-4);
    },
    formattedPhoneNumber() {
      // check if phone number starts with 0
      if (/^0/.test(this.PhoneNumber)) {
        // replace leading 0 with 254
        return this.PhoneNumber.replace(/^0/, "254");
      } else if (/^254/.test(this.PhoneNumber)) {
        // phone number already starts with 254, so ignore
        return this.PhoneNumber;
      } else {
        // phone number doesn't start with 0 or 254, so return as is
        return this.PhoneNumber;
      }
    },
  },
  created() {
    this.updatearrays();
    //this.fetchCustomers();
    let mg={ text: "Margin", value: "profit_margin", sortable: true };
    this.user = JSON.parse(sessionStorage.user);
    if(this.user.user.permissions.includes('change_discounts')){
      this.headers.splice(3,0,mg)
    }
  },
  mounted() {
    this.business = JSON.parse(sessionStorage.business_location);
    this.checkRegister();
  },
  methods: {
    checkRegister() {
      axios
        .get("checkregister/")
        .then((response) => {
          this.registerOpen = response.data.is_open;
          //alert(this.registerOpen)
          if (this.registerOpen === false) {
            this.$router.push({ name: "openregister" });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    closeRegister() {
      Swal.fire({
        title: "Are you sure, you want to close register?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#000000",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes, close it!",
      }).then((result) => {
        if (result.value) {
          axios
            .get("checkregister/?command=close")
            .then(() => {
              Swal.fire("Success!", "Register has been closed.", "success").then(() => {
                window.location.reload();
              });
            })
            .catch((e) => {
              Swal.fire({
                position: "center",
                icon: "error",
                title: "Error!",
                html: e,
                showConfirmButton: false,
                timer: 3000,
              });
            });
        }
      });
    },
    playBeepSound(audio_url) {
      const audio = new Audio(audio_url);
      audio.play();
    },
    getFirstTwoWords(str) {
      // Split the sentence into an array of words
      const words = str.split(" ");
      // Take the first two words
      const firstTwoWords = words.slice(0, 3).join(" ");
      return firstTwoWords;
    },
    formatProductLabel(product) {
      return product.product.title;
    },
    generateTrCode(n) {
      const chars = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
      let result = "";
      for (let i = 0; i < n; i++) {
        result += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return result;
    },
    async updatearrays() {
      axios
        .get(`pos_stock/?filter=${this.filter}&limit=${this.limit}&offset=${this.offset}`)
        .then(async (response) => {
          // JSON responses are automatically parsed.
          this.products = response.data["results"];
          this.totalProducts = response.data["count"];
          this.filteredProducts = this.products;
          //fetch customers
          await axios.get(`contacts/?location_id=${this.business.location_id}&contact_type=Customers&query=`).then(async (res) => {
            var data = await res.data["results"];
            if(!data){
              Swal.fire({
                position: "center",
                icon: "information",
                title: "No business Details Found!",
                html:"Please login to your admin portal and add a business to start selling",
                showConfirmButton: true,
              }).then(() => {
                Swal.close();
              });
            }
            this.customerData = data.map((customer) => ({
              ...customer,
              fullName: `${customer.user.first_name}`,
            }));
            console.log(this.customerData);
            this.customer = this.customerData.find((u) =>
              u.user.username.includes("walkin")
            );
          });
          // Swal.close();
        })
        .catch((e) => {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "" + e,
            showConfirmButton: true,
          }).then((e) => {
            Swal.close(e);
          });
        });
    },
    async searchCustomers(query) {
      if (query) {
        // Assuming you have an API endpoint to fetch customers based on query
        this.isLoading = true;
        setTimeout(async () => {
          await axios
            .get(
              `contacts/?contact_type=Customers&limit=${this.limit}&offset=${this.offset}&query=${query}`
            )
            .then(async (res) => {
              var data = await res.data["results"];
              this.customerData = data.map((customer) => ({
                ...customer,
                fullName: `${customer.user.first_name} ${customer.user.last_name}`,
              }));
              this.isLoading = false;
            })
            .catch((e) => {
              console.log(e);
              this.isLoading = false;
            });
        }, 500);
      }
    },
    applyFilter() {
      var searchTextLower = this.searchText.toLowerCase();
      this.filter = searchTextLower;
      this.updatearrays();
      this.filteredProducts = this.products;
      var cart_item = null;
      cart_item = this.filteredProducts.find((item) => {
        let serial =
          item.product_type == "single" ? item.product.serial : item.variation.serial;
        let sku = item.product_type == "single" ? item.product.sku : item.variation.sku;
        return (
          serial.toLowerCase() === searchTextLower ||
          sku.toLowerCase() === searchTextLower ||
          null
        );
      });
      console.log(cart_item);
      if (cart_item != null) {
        this.searchText = "";
        this.addToCart(cart_item);
      }
    },
    addToCart(item) {
      console.log(item);
      const product = item.product;
      var sku = (sku = item.variation ? item.variation.sku : item.product.sku);
      const quantity = this.quantity;
      var selling_price = item.discount
        ? item.selling_price -
          (item.discount.discount_type == "Fixed"
            ? item.discount.discount_amount
            : item.selling_price * (item.discount.discount_amount / 100))
        : item.selling_price;
      var existingItem = this.cart.find((e) => e.sku === sku);
      var stock_level = item.stock_level;

      if (existingItem) {
        // Item already exists in the cart, update the quantity
        if (existingItem.quantity + quantity > item.stock_level) {
          this.playBeepSound(this.beep_warning_sound);
          Swal.fire({
            icon: "warning",
            title: "Low stock alert!",
            html: `<p>Item <b class='text-danger'>${sku}</b> stock level <b class='text-danger'>${
              item.stock_level
            }</b> cannot accomodate a quantity of <b>${
              existingItem.quantity + quantity
            }</b>?Please update stock first!</p>`,
            showCancelButton: true,
            cancelButtonText: "Close",
            cancelButtonColor: "#fe2413",
            showConfirmButton: false,
            timer: 5000,
          });
        } else {
          existingItem.quantity += quantity;
        }
      } else {
        // Item does not exist in the cart, add it
        this.cart.push({
          sku: sku.toString().replace(".0", ""),
          product,
          variation: item.variation,
          profit_margin:item.profit_margin,
          quantity,
          stock_level,
          selling_price,
        });
        this.playBeepSound(this.beepsound);
      }
    },
    removeFromCart(index) {
      this.cart.splice(index, 1);
    },
    handlePageChange(newPage) {
      this.currentPage = newPage;
      this.limit = this.perPage;
      this.offset = (this.currentPage - 1) * this.perPage;
      this.updatearrays();
    },
    getOrderTax(tax) {
      console.log(tax);
      this.orderTax = Math.ceil(this.TotalPayableBefortax * (tax / 100));
    },
    getOrderDiscount(discount) {
      console.log(discount);
      let d = 0;
      if (discount.type == "Fixed") {
        d = discount.amount;
      } else {
        d = this.total * (discount.amount / 100);
      }
      this.orderDiscount = Math.ceil(d);
    },
    getOrderPackingCharge(charge) {
      console.log(charge);
      this.orderPackingCharge = charge;
    },
    async handleSTKPush(data) {
      this.mpesamodalOpen = true;
      if (this.paymentMethod === "Mpesa") {
        //validate phone number
        if (
          /^(2547|2541)\d{8}$/.test(this.formattedPhoneNumber) &&
          this.formattedPhoneNumber.length === 12
        ) {
          //valid pass
        } else {
          Swal.fire("Phone validation error!", "Invalid phone number!", "warning"); // invalid phone number
          return;
        }
        Swal.fire({
          title: "Please Wait...!",
          html: "Sending STK Push to customer...", // add html attribute if you want or remove
          //timer: 60000, // set timer to 1 min
          allowOutsideClick: false,
          showConfirmButton: false,
          willOpen: () => {
            Swal.showLoading();
          },
        });
        axios
          .post("stkpush/", {
            amount: data.get("grand_total"),
            phone: data.get("phone"),
            tr_description: data.get("tr_desc"),
          })
          .then((response) => {
            console.log(response.data);
            let res_data = response.data;
            Swal.close();
            if (res_data["ResponseCode"] === "0") {
              this.mpesaPassword = res_data["password"];
              this.mpesaLipaTime = res_data["timestamp"];
              this.mpesaCheckoutID = res_data["checkoutID"];
              Swal.fire({
                icon: "success",
                title: "STK push sent successfully!",
                html:
                  "<p>Please wait for customer to complete payment within 15 secs and confirm payment.</p>",
                allowOutsideClick: false,
                showConfirmButton: false,
                timer: 3000,
              });
              this.startCountdown();
            }
          })
          .catch((e) => {
            Swal.close();
            Swal.fire({
              icon: "error",
              title: "STK push failed!",
              html: e,
              allowOutsideClick: false,
              showConfirmButton: false,
              timer: 3000,
            });
          });
      }
    },
    async startCountdown() {
      Swal.fire({
        icon: "info",
        title: "Please Wait...!",
        html: `Confirming payment....`, // add html attribute if you want or remove
        //timer: 60000, // set timer to 1 min
        allowOutsideClick: false,
        showConfirmButton: false,
        willOpen: () => {
          Swal.showLoading();
        },
      });
      let timer = setInterval(async () => {
        if (this.countdown > 0) {
          this.countdown--;
        } else {
          clearInterval(timer);
          // confirm payment
          await axios
            .post("confirmpayment/", {
              password: this.mpesaPassword,
              timestamp: this.mpesaLipaTime,
              checkoutID: this.mpesaCheckoutID,
            })
            .then((response) => {
              console.log(response.data);
              let res = response.data;
              Swal.close();
              if (res["ResultCode"] === "0") {
                Swal.fire({
                  icon: "success",
                  title: res["ResultDesc"],
                  html: res["ResponseDescription"],
                  allowOutsideClick: false,
                  showConfirmButton: false,
                  timer: 5000,
                });
                this.mpesamodalOpen = false;
                this.submitsale(this.sale_data);
              } else {
                Swal.fire({
                  icon: "info",
                  title: res["ResultDesc"],
                  html:
                    res["ResponseDescription"] +
                    "<br/><span class='text-danger'>If this was not intentional, please try requesting payment again!</span>",
                  allowOutsideClick: false,
                  showConfirmButton: false,
                  timer: 6000,
                });
                this.mpesamodalOpen = true;
              }
            })
            .catch((e) => {
              Swal.close();
              Swal.fire({
                icon: "info",
                title: "An error occurred",
                html: e,
                allowOutsideClick: false,
                showConfirmButton: false,
                timer: 5000,
              });
              console.log(e);
            });
        }
      }, 1000); // Update every second
    },
    async submitsale(data) {
      data.append("mpesaCheckoutID", this.mpesaCheckoutID);
      await axios
        .post("salesadd/", data)
        .then((res) => {
          Swal.close();
          Swal.fire({
            position: "center",
            icon: res.data.icon,
            title: res.data.title,
            html: res.data.msg,
            showConfirmButton: false,
            timer: 3000,
          }).then(() => {
            if (res.data.icon === "success") {
              this.playBeepSound(this.beepConfirmSound);
              this.clearValues();
              this.$bvModal.show("modal-print");
            } else {
              this.playBeepSound(this.beep_warning_sound);
            }
          });
        })
        .catch((e) => {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "" + e,
            showConfirmButton: true,
            timer: 3000,
          });
        });
    },
    async confirmPayment(status, method) {
      this.paymentMethod = method;
      if (this.cart.length == 0) {
        this.playBeepSound(this.beep_warning_sound);
        Swal.fire({
          position: "center",
          icon: "warning",
          title: "Not Item in cart!",
          html: "Please add at least one item to cart before submitting sale!",
          showConfirmButton: true,
          time: 5000,
        });
        return;
      }
      var formdata = new FormData();
      this.cart.forEach((item) => {
        let selling_price = item.selling_price;
        let sub_total = (item.quantity * item.selling_price).toFixed(2);
        // Append each value individually
        formdata.append("sku[]", item.sku.toString().replace(".0", "")); // Use "sku[]" to indicate that it's an array
        formdata.append("customer_advance[]", this.customerAdvance);
        formdata.append("unit_price[]", selling_price); // Use "unit_price[]" to indicate that it's an array
        formdata.append("qty[]", item.quantity); // Use "qty[]" to indicate that it's an array
        formdata.append("sub_total[]", sub_total); // Use "sub_total[]" to indicate that it's an array
      });
      //alert(method);
      formdata.append("customer_account", this.selectedCustomerAccount);
      formdata.append("tax_amount", this.orderTax);
      formdata.append("discount_amount", this.orderDiscount);
      formdata.append("change_amount", this.change.toFixed(2));
      formdata.append("tendered_amount", this.amountPaid);
      formdata.append("grand_total", this.grandTotal);
      if (this.paycode == null) {
        Swal.fire("Payment Code validation error!", "Invalid payment code!", "warning"); // invalid phone number
        return;
      }
      formdata.append("status", status);
      formdata.append("paymethod", method);
      formdata.append("attendant", JSON.parse(sessionStorage.user).user.id);
      formdata.append("customer_id", this.customer.user.id);
      this.sale_data = formdata;
      //console.log("sale submitted...")//
      if (this.paymentMethod == "Cash") {
        if (Number(this.amountPaid) >= Number(this.TotalPayable)) {
          this.transactionCompleted = true;
          this.trstatus = status;
          //this.$emit("printReceipt");
        } else {
          alert(
            "Amount paid:" +
              this.amountPaid +
              " is less than Total:" +
              this.TotalPayable.toFixed(2)
          );
          this.playBeepSound(this.beep_warning_sound);
          return;
        }
        await this.submitsale(formdata);
      } else if (this.paymentMethod == "Mpesa") {
        this.trstatus = status;
        this.$bvModal.hide("modal-mpesa");
        this.mpesamodalOpen = true;
        formdata.append("phone", this.formattedPhoneNumber);
        formdata.append(
          "tr_desc",
          this.business.business__name + " (" + this.business.location_name + ")"
        );
        await this.handleSTKPush(formdata);
      } else {
        await this.submitsale(formdata);
      }
    },
    fetchReceiptData() {
      axios
        .get(`sale_receipt_data/?id=${this.myid}`)
        .then((response) => {
          this.receiptData = response.data;
          //console.log(response.data)
          this.$emit("printReceipt");
        })
        .catch((e) => {
          console.log(e);
        });
    },
    handleSubmit() {},
    clearValues() {
      this.transactionCompleted = false;
      this.cart = [];
      this.amountPaid = 0;
      this.change = 0;
      this.paymentMethod = "Cash";
      this.status = "Pending";
      this.sale_data = null;
      this.mpesaCheckoutID = null;
      this.mpesaLipaTime = null;
      this.mpesaPassword = null;
      this.receiptData = null;
      this.countdown = 15;
      this.updatearrays();
    },
  },
};
</script>
<style scoped>
.floating-button {
  position: fixed;
  white-space: nowrap;
  bottom: 120px;
  right: 50px;
  z-index: 100;
}

/* Adjust the button style as needed */
.floating-button button {
  border-radius: 5%;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 40px;
}
.viewport {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  align-items: center;
  justify-content: center;
}
</style>
